.App {
  text-align: center;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #CCC;
}

h1 {
  text-transform: uppercase;
  font-weight: 900;
}

label {
  width: 8rem;
  display: inline-block;
  text-align: right;
}

input:not([type="submit"]), select {
  width: 20rem;
  height: 1.5rem;
}

input[type="submit"] {
  line-height: 1.5rem;
  margin-top: 2rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

input, select {
  padding: 0.5rem;
  margin: 0.5rem;
  background: black;
  border: solid 1px #666;
  color: #CCC;
  font-size: 1rem;
}

select {
  -moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
  border-radius: 0;
  box-sizing: content-box;
}

.description {
  padding-left: 9rem;
  text-align: left;
}



@media(max-height: 900px) {
  .App-header {
    margin-top: 1.5rem;
  }
}



@media(max-width: 550px) {
  body {
    padding: 1.25rem;
  }

  .form {
    width: 100%;
  }

  label {
    width: 100%;
    text-align: left;
    margin-top: 0.75rem;
  }

  input:not([type="submit"]), select {
    width: calc(100% - 1rem);
    margin: 0.5rem 0;
  }

  .description {
    padding-left: 0 !important;
  }
}
